<template>
  <div>
    <h1 class="page-title fs-2500">Plan du site</h1>
    <h2 class="second-header">Navigation principale</h2>

    <!-- Contenu statique -->
    <li class="main">
      <h4>Services non-authentifiés</h4>
      <h3>
        <router-link :to="{ name: 'Accueil' }"
          >Accueil de Portailpro.gouv</router-link
        >
      </h3>
    </li>
    <li class="main">
      <h3>
        <router-link :to="{ name: ROUTES_NAMES.PAIEMENTS_DIRECTS }"
          >Paiements directs
        </router-link>
      </h3>
    </li>
    <li>
      <a
        id="pai_01"
        @click="navigateToPaiementImpotsLocauxPro($event)"
        class="pl-3 font-normal"
        href="#"
        >Paiement des impôts locaux <span class="pro_label">PRO</span></a
      >
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Payfip - Paiement d'une facture publique - site externe"
        id="pai_02"
        :href="URL_PAYFIP_GOUV"
        target="_01"
      >
        Paiement d’une facture publique
      </a>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Douane.gouv.fr - Paiement de droits / taxes de Douane - site externe"
        id="pai_03"
        :href="URL_DOUANE_GOUV_PAIEMENT_TAXE_DROIT"
        target="_01"
      >
        Paiement de droits / taxes de Douane
      </a>
    </li>

    <!-- Contenu statique, suite  -->
    <li class="main">
      <h3>
        <router-link :to="{ name: ROUTES_NAMES.DEMARCHES_DIRECTES }"
          >Démarches directes</router-link
        >
      </h3>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Formalites.entreprises.gouv.fr - Guichet formalités - site externe"
        id="dem_01"
        :href="URL_FORMALITES_ENTREPRISES_GOUV"
        target="_01"
        >Guichet formalités
      </a>
    </li>
    <li>
      <a
        v-if="!isAuthentifie"
        class="pl-3 font-normal"
        title="Net-entreprises.fr - Démarches sociales net-entreprises - site externe"
        id="dem_02"
        :href="URL_NET_ENTREPRISE_NON_AUTH"
      >
        Démarches sociales net-entreprises
      </a>
      <a
        v-if="isAuthentifie"
        class="pl-3 font-normal"
        title="Net-entreprises.fr - Démarches sociales net-entreprises - nouvel onglet"
        id="dem_02"
        target="_01"
        :href="URL_NET_ENTREPRISE_AUTH"
      >
        Démarches sociales net-entreprises
      </a>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Entreprendre.Service-Public.fr - Le site officiel d'information administrative pour les entreprises - site externe"
        id="dem_03"
        :href="URL_ENTREPRENDRE_SERVICE_PUBLIC"
        target="_01"
      >
        Le site officiel d’information administrative pour les entreprises
      </a>
    </li>

    <li class="main">
      <h3>
        <router-link :to="{ name: 'Ressources' }">Ressources</router-link>
      </h3>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Oups.gouv.fr - M’informer sur le droit à l’erreur - site externe"
        id="res_01"
        :href="URL_OUPS_GOUV_FR"
        target="_blank noopener noreferrer"
      >
        M’informer sur le droit à l’erreur
      </a>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Economie.gouv.fr - À quoi servent mes impôts - site externe"
        id="res_02"
        :href="URL_A_QUOI_SERVE_IMPOTS"
        target="_blank noopener noreferrer"
      >
        À quoi servent mes impôts
      </a>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Urssaf.fr - À quoi servent mes cotisations sociales - site externe"
        id="res_03"
        :href="URL_A_QUOI_SERVE_COTISATIONS"
        target="_blank noopener noreferrer"
      >
        À quoi servent mes cotisations sociales
      </a>
    </li>
    <li>
      <a
        class="pl-3 font-normal"
        title="Impots.gouv.fr - Calendrier fiscal générique - site externe"
        id="res_04"
        :href="URL_CALENDRIER_FISCAL"
        target="_blank noopener noreferrer"
      >
        Calendrier fiscal générique
      </a>
    </li>
    <li v-if="cds" class="main mt-3">
      <router-link :to="{ name: 'Outil de recherche' }" id="pla_10"
        >Outil de recherche</router-link
      >
    </li>
    <li v-if="cds" class="main mt-3">
      <router-link :to="{ name: 'Aide' }" id="pla_10">Assistance</router-link>
    </li>

    <h4>Services authentifiés</h4>

    <!-- Contenu statique FIN -->

    <ul class="pln-3">
      <li v-for="(item, index) in navigation" :key="item.name" class="main">
        <router-link :id="`pla-${index + 1}`" :to="{ name: item.name }">
          {{ item.name }}</router-link
        >
      </li>
      <div v-for="(item, index) in LIENS_UTILES" :key="index">
        <template v-if="item.title == 'Rubriques'">
          <h3 class="pln-3">Autres rubriques</h3>
          <ul class="pl-3">
            <li v-for="(navItem, navIndex) in item.items" :key="navIndex">
              <div v-if="!navItem.ignoreRubrique">
                <router-link
                  class="font-normal"
                  :to="
                    window.width < 760 && navItem.notSupportMobile
                      ? {
                          name: 'Page ne supporte pas mobile',
                          params: {
                            uri: '/notsupported',
                            name: navItem.title
                          }
                        }
                      : { name: navItem.name }
                  "
                  v-if="navItem.useRouter"
                  :id="`pla-${index + '' + (navIndex + 10)}`"
                >
                  {{ navItem.title }}
                </router-link>

                <a
                  v-else
                  class="font-normal"
                  :id="`pla-${index + '' + (navIndex + 10)}`"
                  :href="navItem.href || '#'"
                  :target="
                    navItem.target ? navItem.target : 'noopener noreferrer'
                  "
                  >{{ navItem.text }}</a
                >
              </div>
            </li>
          </ul>
        </template>
      </div>
      <!-- Contenu statique-->
      <li class="main">
        <h3>Gérer mon compte</h3>
      </li>
      <li>
        <router-link
          :to="{ name: 'Gestion des rôles' }"
          id="pla_6"
          class="pl-3 font-normal"
          >Gérer les rôles</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'Gestion des habilitations' }"
          class="pl-3 font-normal"
          id="pla_7"
          >Gérer mes habilitations</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'Gestion des informations personnelles' }"
          class="pl-3 font-normal"
          id="pla_7"
          >Gérer mes informations personnelles</router-link
        >
      </li>
      <li v-if="cds" class="main mt-3">
        <router-link :to="{ name: 'Aide' }" id="pla_10">Assistance</router-link>
      </li>
      <li class="main mt-3">
        <router-link :to="{ name: 'Messagerie' }" id="pla_9"
          >Messagerie</router-link
        >
      </li>
    </ul>

    <h2 class="second-header">Liens utiles</h2>
    <div v-for="(item, index) in LIENS_UTILES" :key="index">
      <template v-if="item.title == 'Informations'">
        <h3 class="pl-3">{{ item.title }}</h3>
        <ul class="pl-2_1">
          <li v-for="(navItem, navIndex) in item.items" :key="navIndex">
            <div v-if="!navItem.ignoreRubrique">
              <router-link
                class="font-normal"
                :to="
                  window.width < 760 && navItem.notSupportMobile
                    ? {
                        name: 'Page ne supporte pas mobile',
                        params: {
                          uri: '/notsupported',
                          name: navItem.title
                        }
                      }
                    : { name: navItem.name }
                "
                v-if="navItem.useRouter"
                :id="`pla-${index + '' + (navIndex + 10)}`"
              >
                {{ navItem.title }}
              </router-link>

              <a
                v-else
                class="font-normal"
                :id="`pla-${index + '' + (navIndex + 10)}`"
                :href="navItem.href || '#'"
                :target="
                  navItem.target ? navItem.target : 'noopener noreferrer'
                "
                >{{ navItem.text }}</a
              >
            </div>
          </li>
        </ul>
      </template>
    </div>
    <h2 class="second-header">Pied de page</h2>
    <ul class="pl-3">
      <!--<li>
        <router-link to="plan-du-site" title="Plan du site"
          >Plan du site</router-link
        >
      </li>-->
      <li class="main">
        <router-link :to="{ name: ROUTES_NAMES.ACCESSIBILITE }" id="pla_11"
          >Accessibilité : partiellement conforme</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: ROUTES_NAMES.SCHEMA_ACCESSIBILITE_2022_2024 }"
          class="pl-3 font-normal"
          id="pla_12"
        >
          Schéma pluriannuel d’accessibilité 2022 - 2024 de Portailpro.gouv
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: ROUTES_NAMES.PLAN_ACCESSIBILITE_2023 }"
          id="pla_13"
          class="pl-3 font-normal"
          >Plan annuel d’accessibilité pour 2023 de Portailpro.gouv
        </router-link>
      </li>

      <li>
        <router-link
          class="pl-3 font-normal"
          :to="{ name: ROUTES_NAMES.DECLARATION_DACCESSIBILITE_RGAA_NON_AUTH }"
          id="pla_19"
          >Déclaration d’accessibilité au Référentiel général d'amélioration de
          l'accessibilité - RGAA - Services non authentifiés</router-link
        >
      </li>

      <li>
        <router-link
          :to="{ name: ROUTES_NAMES.DECLARATION_DACCESSIBILITE_RGAA_AUTH }"
          class="pl-3 font-normal"
          id="pla_14"
          >Déclaration d’accessibilité au Référentiel général d'amélioration de
          l'accessibilité - RGAA - Services authentifiés</router-link
        >
      </li>

      <li>
        <router-link
          :to="{ name: ROUTES_NAMES.CONTACTER_REFERENT_ACCESSIBILITE }"
          class="pl-3 font-normal"
          id="pla_15"
          >Contacter le référent accessibilité numérique de
          Portailpro.gouv</router-link
        >
      </li>

      <li class="main mt-3">
        <router-link :to="{ name: ROUTES_NAMES.MENTIONS_LEGALES }" id="pla_16"
          >Mentions légales</router-link
        >
      </li>
      <li class="main">
        <router-link
          :to="{ name: ROUTES_NAMES.DONNNEES_PERSOS_COOKIES }"
          id="pla_17"
          >Données personnelles et cookies</router-link
        >
      </li>
      <!--
      <li>
        <router-link :to="{  name: 'Gestion des cookies'}  " title="Gestion des cookies"
          >Gestion des cookies</router-link
        >
      </li>-->

      <li class="main">
        <router-link
          :to="{ name: ROUTES_NAMES.CONDITIONS_GENERALES_UTILISATION }"
          id="pla_18"
          >Conditions Générales d'Utilisation</router-link
        >
      </li>
      <li class="main mb-5">
        <router-link :to="{ name: ROUTES_NAMES.CONTACT }" id="pla-10"
          >Contact</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import routes from '../router/routes';
import {
  LIENS_UTILES,
  URL_PAYFIP_GOUV,
  URL_DOUANE_GOUV_PAIEMENT_TAXE_DROIT,
  URL_FORMALITES_ENTREPRISES_GOUV,
  URL_NET_ENTREPRISE_NON_AUTH,
  URL_NET_ENTREPRISE_AUTH,
  URL_ENTREPRENDRE_SERVICE_PUBLIC,
  URL_TARIFS_DOUANIERS,
  URL_OUPS_GOUV_FR,
  URL_A_QUOI_SERVE_IMPOTS,
  URL_A_QUOI_SERVE_COTISATIONS,
  URL_CALENDRIER_FISCAL,
  ROUTES_NAMES,
  SOURCE_PAGE_PLAN_SITE
} from '../constantes';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { mapActions, mapGetters } from 'vuex';
import { sendTag, isFeatureActive } from '../utils/methodsUtils';

// TODO dynamiser la page et mettre les liens dans un fichier de conf pour être partagé avec les autres pages
export default {
  name: 'PlanDuSite',
  mixins: [SkipLinkMixin],
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      cds: false
    };
  },
  computed: {
    LIENS_UTILES() {
      return LIENS_UTILES;
    },
    ROUTES_NAMES() {
      return ROUTES_NAMES;
    },
    navigation() {
      const nomPages = [];
      return routes[0].children.filter(
        (e) =>
          (!e.hidden && e.name !== 'Autres rubriques') ||
          nomPages.includes(e.name)
      );
    },
    URL_PAYFIP_GOUV() {
      return URL_PAYFIP_GOUV;
    },
    URL_DOUANE_GOUV_PAIEMENT_TAXE_DROIT() {
      return URL_DOUANE_GOUV_PAIEMENT_TAXE_DROIT;
    },
    URL_FORMALITES_ENTREPRISES_GOUV() {
      return URL_FORMALITES_ENTREPRISES_GOUV;
    },
    URL_NET_ENTREPRISE_NON_AUTH() {
      return URL_NET_ENTREPRISE_NON_AUTH;
    },
    URL_NET_ENTREPRISE_AUTH() {
      return URL_NET_ENTREPRISE_AUTH;
    },
    URL_ENTREPRENDRE_SERVICE_PUBLIC() {
      return URL_ENTREPRENDRE_SERVICE_PUBLIC;
    },
    URL_TARIFS_DOUANIERS() {
      return URL_TARIFS_DOUANIERS;
    },
    URL_OUPS_GOUV_FR() {
      return URL_OUPS_GOUV_FR;
    },
    URL_A_QUOI_SERVE_IMPOTS() {
      return URL_A_QUOI_SERVE_IMPOTS;
    },
    URL_A_QUOI_SERVE_COTISATIONS() {
      return URL_A_QUOI_SERVE_COTISATIONS;
    },
    URL_CALENDRIER_FISCAL() {
      return URL_CALENDRIER_FISCAL;
    },
    user() {
      return {
        email: this.email
      };
    },
    ...mapGetters(['email']),
    isAuthentifie() {
      return !this.$route.meta.withoutAuth && this.email && this.email != '';
    }
  },
  methods: {
    ...mapActions(['setPreviousPath', 'setRouteData']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    navigateToPaiementImpotsLocauxPro(e) {
      if (e) {
        e.preventDefault();
      }
      sendTag('paiements-directs', 'paiement-des-impots-locaux-pro', '', '');
      localStorage.setItem(
        'libelleTeleservice',
        'Paiement des impôts locaux PRO'
      );
      this.setPreviousPath('');
      const routeData = {
        source: SOURCE_PAGE_PLAN_SITE,
        uri: process.env.VUE_APP_IMPOT_LOC_PRO_IFRAME
      };
      this.setRouteDataAndPush(routeData, 'Téléservice');
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  async mounted() {
    this.cds = isFeatureActive('PCR2-9132');
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: $base-color;
  border-bottom: 2px solid $base-color;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

li {
  list-style: none;
}
li.main {
  margin-top: 1rem;
  a {
    color: #161616;
  }
}
li:not(.main) {
  padding: 0.2rem 0px;
}
h4 {
  color: $base-color;
  font-size: 1.5rem;
  margin-bottom: 16px;
  margin-top: 16px;
  line-height: 1.5rem;
  text-decoration: none;
  box-shadow: none;
  font-weight: bold;
}

li a,
h3 {
  margin: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  box-shadow: none;
  font-weight: bold;
}
h3 + ul a {
  font-weight: normal;
}
h3 {
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  box-shadow: none;
  font-weight: bold;
}

a:hover {
  color: rgb(73, 80, 87);
}
a {
  text-decoration: underline !important;
  background-image: none;
}
li a pl-3 font-normal {
  font-weight: normal;
}
.pln-3 {
  padding-left: 0;
}

.font-normal {
  font-weight: normal;
  color: rgb(73, 80, 87);
}
.pro_label {
  font-weight: bold;
}
</style>
